import React, { useState, useMemo, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { compact, uniq, get, filter } from 'lodash';
import { graphql, PageProps } from 'gatsby';

import ComponentRenderer from '../../components/ComponentRenderer/ComponentRenderer';
import CareersCard from '../../components/CareersCard';
import Dropdown, { OptionType } from '../../components/Dropdown';
import Divider from '../../components/Divider';
import InfiniteScroll from '../../components/InfiniteScroll';
import SEO from '../../components/SEO/SEO';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../../components/LayoutWrapper';
import { StrapiImageAndTextComponent } from '../../components/StrapiComponents/StrapiImageAndTextComponent';
import { StrapiFooterCtaComponent } from '../../components/StrapiComponents/StrapiFooterCta';
import { CareerPostsQuery, CareerCardFragment } from '../../../graphql.schema';
import { GlobalPageContext, PaginationContext } from '../../types';
import { v4tov3base } from '../../lib/mapV4toV3';

import * as styles from './careers-index.module.scss';

type CareersIndexType = GlobalPageContext & PaginationContext;

const CareersIndex: React.FC<PageProps<CareerPostsQuery, CareersIndexType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3 });
  const { allCareerPosts, careersCta } = data.strapi;
  const careerPosts: CareerCardFragment[] = get(data, 'strapi.careerPosts', []);
  const pages = get(data, 'strapi.pages', []);
  const intl = useIntl();
  if (!websiteLocale) return null;

  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [filteredCareers, setFilteredCareers] = useState<CareerCardFragment[]>([]);
  const [list, setCareersList] = useState<CareerCardFragment[]>(careerPosts);
  const [isInitialLoadDone, setIsInitialLoadDone] = useState<boolean>(false);
  const currentCareerPosts = useMemo(() => {
    if (selectedJobTypes.length > 0 || selectedLocations.length > 0) {
      return filteredCareers;
    }
    return compact(list);
  }, [list, filteredCareers]);

  useEffect(() => {
    setCareersList(careerPosts.slice(0, 10));
    setIsInitialLoadDone(true);
  }, [careerPosts]);

  useEffect(() => {
    let filtered = compact(allCareerPosts);

    if (selectedJobTypes.length > 0) {
      filtered = filtered.filter((career) => compact(selectedJobTypes).includes(career.jobType?.name ?? ''));
    }
    if (selectedLocations.length > 0) {
      filtered = filtered.filter((career) => compact(selectedLocations).includes(career.city?.name ?? ''));
    }
    setFilteredCareers(filtered.slice(0, 10));
  }, [selectedJobTypes, selectedLocations]);

  const getFilteredCareersPost = useMemo(() => {
    const filterByJobTypes = filter(compact(careerPosts), (career: CareerCardFragment) =>
      compact(selectedJobTypes).includes(career.jobType?.name ?? ''),
    );
    const filterByLocation = filter(compact(careerPosts), (career: CareerCardFragment) =>
      compact(selectedLocations).includes(career.city?.name ?? ''),
    );
    return [...filterByJobTypes, ...filterByLocation];
  }, [careerPosts, selectedJobTypes, selectedLocations]);
  const uniqueJobTypes = useMemo(() => uniq(compact(allCareerPosts).map((c) => c.jobType?.name)), [allCareerPosts]);
  const uniqueLocations = useMemo(() => uniq(compact(allCareerPosts).map((c) => c.city?.name)), [allCareerPosts]);

  const handleDropdownChange = (val: OptionType[], type: string) => {
    const valueStrings = val.map((v) => v.value);
    switch (type) {
      case 'job-type':
        setSelectedJobTypes([...valueStrings]);
        break;
      case 'location-type':
        setSelectedLocations([...valueStrings]);
        break;

      default:
        return null;
    }
  };
  const showFooterCta = pages[0]?.pageSettings?.showFooterCta ?? true;
  const footerCta = pages[0]?.pageSettings?.customFooterCta?.documentId
    ? pages[0].pageSettings?.customFooterCta
    : websiteLocale.footerCta;
  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={pages[0]?.seo?.metaTitle ?? pages[0]?.pageSettings?.title ?? intl.formatMessage({ id: 'seo.careers.title' })}
        description={pages[0]?.seo?.metaDescription ?? pages[0]?.pageSettings?.metaDescription ?? intl.formatMessage({ id: 'seo.careers.description' })}
        image={pages[0]?.seo?.metaImage?.url}
        avoidIndexing={pages[0]?.pageSettings?.avoidIndexing ?? false}
        canonicalURL={pages[0]?.seo?.canonicalURL}
        keywords={pages[0]?.seo?.keywords}
        metaSocial={pages[0]?.seo?.metaSocial}
        structuredData={pages[0]?.seo?.structuredData}
        currentPage={pageContext}
      />
      <div className={classnames('container', styles.root)}>
        <h1 className="title color-a">
          <FormattedMessage id="careersIndexHeading" defaultMessage="Careers" />
        </h1>
        <Divider paddingTop="75px" marginBottom="25px" />
        <div className="is-flex-desktop">
          <Dropdown
            placeholder={intl.formatMessage({ id: 'placeholderJobType', defaultMessage: 'Job Type' })}
            options={compact(uniqueJobTypes).map((name) => ({ label: name, value: name }))}
            onChange={(val) => handleDropdownChange(val, 'job-type')}
          />
          <Dropdown
            placeholder={intl.formatMessage({ id: 'placeholderLocation', defaultMessage: 'Location' })}
            options={compact(uniqueLocations).map((name) => ({ label: name, value: name }))}
            onChange={(val) => handleDropdownChange(val, 'location-type')}
          />
        </div>
        {filteredCareers.length < 1 ? (
          <div className={classnames('column is-5 p-0 mt-10', styles.noVacancies)}>
            <h4 className="title is-4">
              <FormattedMessage id="noCareersIndexHeading" defaultMessage="No current career opportunities." />
            </h4>
            <p>
              <FormattedMessage
                id="noPromotionsIndexDescription"
                defaultMessage="We’re not currently hiring for any positions, please check back soon."
              />
            </p>
          </div>
        ) : null}
        <div className={styles.gridWrapper}>
          {selectedJobTypes.length > 0 || selectedLocations.length > 0
            ? compact(filteredCareers).map(({ documentId, title, slug, short, jobType, city }) => {
                if (!slug) return null;
                return (
                  <CareersCard
                    linkText={intl.formatMessage({ id: 'careersCardText', defaultMessage: 'View Opportunity' })}
                    slugBase={pageContext.pathPrefixes.careers}
                    jobIsActive={selectedJobTypes.includes(jobType?.name ?? '')}
                    cityIsActive={selectedLocations.includes(city?.name ?? '')}
                    jobType={jobType?.name ?? ''}
                    city={city?.name ?? ''}
                    key={`career-${documentId}`}
                    title={title ?? 'N/A'}
                    short={short ?? ''}
                    slug={slug}
                  />
                );
              })
            : currentCareerPosts.map(({ documentId, title, slug, short, jobType, city }) => {
                if (!slug) return null;
                return (
                  <CareersCard
                    linkText={intl.formatMessage({ id: 'careersCardText', defaultMessage: 'View Opportunity' })}
                    slugBase={pageContext.pathPrefixes.careers}
                    jobIsActive={selectedJobTypes.includes(jobType?.name ?? '')}
                    cityIsActive={selectedLocations.includes(city?.name ?? '')}
                    jobType={jobType?.name ?? ''}
                    city={city?.name ?? ''}
                    key={`career-${documentId}`}
                    title={title ?? 'N/A'}
                    short={short ?? ''}
                    slug={slug}
                  />
                );
              })}
        </div>
        {isInitialLoadDone && (
          <InfiniteScroll
            selectedCategories={[...selectedJobTypes, ...selectedLocations]}
            categoryList={getFilteredCareersPost}
            filteredList={currentCareerPosts}
            completeList={careerPosts}
            list={currentCareerPosts}
            setList={(list) => setCareersList(list)}
            setFilteredList={(filteredList) => setFilteredCareers(filteredList)}
          />
        )}
      </div>
      <div className={classnames('page-components')}>
        {pages[0]?.components &&
          pages[0]?.components?.map((component: any, idx: any) =>
            component ? (
              <ComponentRenderer
                hasRTL={websiteLocale?.hasRightToLeftLanguage}
                component={component}
                key={`page-component-${component.__typename}-${idx}`}
              />
            ) : null,
          )}
      </div>
      {careersCta?.ctaPanel && <StrapiImageAndTextComponent {...careersCta.ctaPanel} />}
      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export default intlWrapperHOC(v4tov3HOC(CareersIndex));

export const query = graphql`
fragment CareerCard on Strapi_CareerPost {
  title
  short
  slug
  jobType: job_type {
      data {
      documentId
      attributes {
        name
      }
    }
  }
  city {
     data {
      documentId
      attributes {
        name
      }
    }
  }
  publishedAt
}

query CareerPosts($websiteLocaleId: ID!, $careersCtaId: ID!, $hasCareersCta: Boolean!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    careersCta(documentId: $careersCtaId) @include(if: $hasCareersCta) {
      data {
        documentId
        attributes {
          ctaPanel: cta_panel {
            ...ImageAndTextComponent
          }
        }
      }
    }
    allCareerPosts: careerPosts(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}}
      locale: $locale
      sort: "publishedAt:desc"
      pagination: {limit: 100}
    ) {
      data {
        documentId
        attributes {
          ...CareerCard
        }
      }
    }
    careerPosts(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}}
      locale: $locale
      sort: "publishedAt:desc"
      pagination: {limit: 100}
    ) {
      data {
        documentId
        attributes {
          localizations {
            data {
              attributes {
                locale
                slug
              }
            }
          }
          ...CareerCard
        }
      }
    }
    pages(
      filters: {website_locale: {documentId: {eq: $websiteLocaleId}}, index_page: {eq: "Careers"}}
      locale: $locale
    ) {
      data {
        documentId
        attributes {
          title
          slug
          components {
            ...PageComponents
          }
          page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;
